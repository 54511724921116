@import 'styles/variables';

#profile {

    .checkmark {
        height: 16px;
        width: 16px;
        margin-left: 10px;
        content: url('/static/icons/checkmark_unfilled.svg');
        
        &:hover {
            content: url('/static/icons/checkmark_filled.svg');
        }
    }

    .pending {
        height: 16px;
        width: 16px;
        margin-left: 10px;
        content: url('/static/icons/pending_unfilled.svg');

        &:hover {
            content: url('/static/icons/pending_filled.svg');
        }
    }

    .mdc-dialog .mdc-dialog__surface {
        max-height: 208px !important;
        overflow-y: hidden;
    }

    .dialog-icon {
        width: 32px !important;
        height: 34px !important;
        background-color: transparent !important;
        color: $sign-up-blue !important;
        border-radius: 100%;
        font-size: 42px;
        margin-bottom: 10px;
    }

    .mdc-dialog .mdc-dialog__title {
        font-size: $extralarge !important;
        padding: 0 18px 0 !important;
        font-weight: 600 !important;
    }

    .divisor-title {
        width: 54px;
        height: 5px;
        margin-bottom: 15px;
        margin-left: 20px;

        @media (max-width:$desktop) {
            width: 40px;
        }

        @media (max-width:$phone) {
            width: 30px;
            margin-left: 30px;
        }
    }

    .mdc-checkbox__background {
        border-color: $palegray;
        background-color: $palegray;
        width: 25px;
        height: 25px;
    }

    .checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
        border-color: rgba(0, 0, 0, 0) !important;
        background-color: $palegray !important;
    }

    .checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
        margin: auto;
        color: rgba(88, 101, 242, 1);
        width: 15px;
        height: 15px;
    }

    .profile-hover {
        margin-top: 19px !important;
    }

    .single-container {
        background: $white;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        flex: 1 1 auto;
        height: auto;
        width: auto;
        padding: 22px 20px;

        @media (max-width: $desktop) {
            width: 85%;
            min-width: 705px;
            margin-right: auto;
            margin-left: auto;
        }

        @media (max-width: $phone) {
            width: 350px;
            min-width: 0 !important;
        }
    }

    .edit {
        display: none;
        color: $sign-up-blue;
    }

    .info-field {

        &:hover {
            & .edit {
                display: block;
            }
        }
    }

    .profile-info {
        @media (max-width: $phone) {
            max-width: 170px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @media (max-width: $phone) {
            max-width: 110px;
        }
    }

    .marketing-preferences {
        height: 144px !important;
        padding-bottom: 0 !important;
    }

    .mb-3-4 {
        margin-bottom: 20px;
    }

    .profile-field {
        width: 145px;
        opacity: 0.4;

        @media (max-width: $phone) {
            max-width: 80px;
        }

        @media (max-width: $smallphone) {
            max-width: 55px;
        }
    }

    .title {
        font-size: $largeplus;
        margin-left: 20px;

        @media (max-width:$desktop) {
            font-size: $large;
        }

        @media (max-width:$phone) {
            font-size: $medium;
            margin-left: 30px;
        }
    }

    .marketing-preferences {
        position: relative;
        margin-bottom: 20px;
    }

    .newsletter-container {
        position: absolute;
        left: 38px;

        .checkbox {
            margin-left: -10px;
            margin-right: 5px;
        }
    }

    .custom-tooltip {
        top: -16.5px;
        left: 35px !important;

         &.mdc-tooltip .mdc-tooltip__surface {
            min-height: 32px;
            font-size: $small;
            font-weight: 300;
            padding: 6px 15px 6px 6px;
            transform: scale(1) !important;
            text-align: left;
            min-width: 300px;
            line-height: 15px;
            letter-spacing: 0.4px;
        }

        &.tooltip-pending .mdc-tooltip__surface {
            min-width: 400px !important;
        }

        &::after {
            transform: rotate(270deg) !important;
            left: -5px;
            top: 25%;
        }

        @media (max-width: $tablet) {
            top: 20px;
            left: -150px !important;
            min-width: 200px;

            &::after {
                transform: rotate(0deg) !important;
                left: 67.5%;
                top: -25px;
            }
        }
    }

    @media (max-width: $tablet) {
        .profile-field {
            margin-right: 151px;
        }
    }

    @media (max-width: $phone) {
        .profile-field {
            margin-right: 8px;
        }

        .contact-error {
            font-size: $extra-tiny;
        }

        .single-container {
            padding: 22px 8px;
        }
    }
}
