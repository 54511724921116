@import 'styles/variables';

#orders {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    background-color: $white;
    @media (max-width: $desktop) {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 0;
    }

    @media (max-width: $phone) {
        padding-left: 17px;
        padding-right: 17px;
    }

    .single-container {
        background: $white;
        margin-top: 15px;
        border-radius: 4px;
        flex: 1 1 auto;
        height: auto;
        padding: 21px 30px;
        padding-left: 0;
    }

    .title-padding {
        padding-right: 30px;

        @media (max-width: $tablet) {
            padding-right: 20px;
        }

        @media (max-width: $phone) {
            padding-right: 0;
        }
    }

    .mdc-data-table__cell, .mdc-data-table__header-cell {
        border: none;
    }

    .mdc-data-table__sort-icon-button {
        border: none;
        background-color: $transparent;
        height: 15px;
        width: 17px;
        margin-left: 0;
        color: $black;

        @media (max-width: $tablet) {
            opacity: 1;
        }
    }

    .sort-asc {
        margin-top: -55%;
    }

    .sort-desc {
        margin-top: -60%;
    }

    .status-column {
        width: 130px !important;

        @media (max-width: $phone) {
            width: 150px !important;
        }
    }

    .title {
        font-size: $homecardhuge;

        @media (max-width:$desktop) {
            font-size: $largeplusplus;
        }

        @media (max-width:$phone) {
            font-size: $large;
        }
    }

    .return {
        transform: translateX(-10px);
        margin-bottom: 15px;
    }

    mdc-data-table {
        border: none;
        margin-top: 19px;
    }

    thead {
        border-top: 1px solid $divisor-gray !important;
        th {
            color: $black;
            font-size: $medium;
            font-weight: 700;
            padding-left: 5px;
            border: none !important;
        }

        @media (max-width: $phone) {
            th {
                padding-left: 19px;
                padding-top: 7px;
                padding-bottom: 7px;
            }
        }
    }

    tbody {
        font-size: $regular;

        td {
            padding: 15px 5px;
        }

        tr {
            border-top: 1px solid $divisor-gray !important;
            border-bottom: 1px solid $divisor-gray !important;
            &:hover {
                background-color: $lightergray  !important;
                cursor: pointer;
            }
        }
    }

    .pseudo-table #ds-status-tag {
        font-size: $tiny;
        width: 110px;
        margin-left: -10px;

        .tag {
            height: 24px !important;
        }
    }

    .search-bar {
        width: 448px;
        border-radius: 4px;
        border: 1px solid rgba(242, 242, 242, 1);
        height: 50px;
    }

    .img-container {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $palegray;
        margin-right: 5px;
        .product-img {
            width: 16px;
            height: 18px;
            object-fit: contain;
            filter: brightness(0) saturate(100%) invert(47%) sepia(35%) saturate(6945%) hue-rotate(220deg) brightness(92%) contrast(106%);
        }
    }

    .arrow-icon {
        border: 0;
        cursor: pointer;
        height: 13px;
        transition: 0.3s ease;
        width: 10px;
        filter: invert(44%) sepia(0%) saturate(1950%) hue-rotate(149deg) brightness(97%) contrast(84%);

        @media (max-width: $phone) {
            position: absolute;
            top: 19px;
            right: 22px;
        }
    }

    @media (max-width: $tablet) {
        .single-container {
            padding: 28px 20px;
        }

        .section-title {
            font-size: $large;
        }

        thead {
            th {
                font-size: $regular;
            }
        }

    }

    @media (max-width: 770px) {
        .search-bar {
            width: 300px;
        }
    }

    @media (max-width: $phone) {
        .single-container {
            padding: 0
        }

        .search-bar {
            width: 170px;
            font-size: $small;
        }

        .placeholder-bar {
            font-size: $small !important;
        }
    }

    @media (max-width: 450px) {
        .search-bar {
            width: 115px;
        }
    }

    @media (max-width: 400px) {
        .search-bar {
            width: 75px;
        }
    }

    .order-rate {
        margin-left: 45px;
        margin-top: -18px;
    }
}
