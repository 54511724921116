@import 'styles/variables';
@import 'styles/animations';

#verification {
    .single-container {
        background: $white;
        border-radius: 12px;
        flex: 1 1 auto;
        min-height: 35px;
        height: auto;
        padding: 14px 25px;
        flex-flow: column;
        margin-bottom: 30px;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);

        &.cursor-pointer:hover {
            outline: 1px solid $purplehover;

            .section-icons {
                display: block;
                font-size: $largeplusplus;
            }
        }

        &.cursor-pointer.active {
            outline: 1px solid $purple-text;

            .section-icons {
                display: block;
            }
        }

        &.update:hover {
            .edit, .section-icons {
                display: block;
                margin-left: -16px;
                font-size: $largeplusplus;
            }

            .verified {
                margin-right: 18px;
            }
        }
        
        &:hover {
            .checkmark {
                content: url('/static/icons/checkmark_filled.svg');
            }

            .pending {
                content: url('/static/icons/pending_filled.svg');
            }

            .phone {
                content: url('/static/icons/phone_filled.svg');
            }

            .email {
                content: url('/static/icons/email_filled.svg');
                width: 35px;
            }

            .finger {
                content: url('/static/icons/finger_filled.svg');
                width: 35px;
            }
            
            .address {
                content: url('/static/icons/address_filled.svg');
            }
        }
    }

    .verified {
        color: $darkergray-medium;
        font-size: $regular;
    }

    .vertical-progress-bar {
        display: none;

        @media (max-width: $phone) {
            display: block;
        }
    }

    .horizontal-progress-bar {
        display: none;

        @media (max-width: $widescreen) {
            display: block;
        }

        @media (max-width: $phone) {
            display: none;
        }
        
    }

    .section-header {
        font-size: $medium;
    }

    .verification-divider {
        width: 54px;
        height: 4px;
        background: $ds-home-button; 
        bottom: 17px;
        position: relative;
    }

    .section-subtitle {
        font-size: $small;
    }

    .disabled {
        opacity: 20%;
        cursor: auto;
        pointer-events: none;
    }

    .input-inner-container {
        margin-top: 8px;
        width: 410px;
    }

    .ds-form-control {
        max-width: 410px;

        &:focus {
            box-shadow: none;
        }

        @media (max-width: $desktop) {
            max-width: 100%;
        }
    }

    .input-outer-container {
        margin-left: 0;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 20px;
        top: 12.5px;
    }

    .circle-icon {
        position: absolute;
        right: 24px;
        top: 17px;
        width: 16px;
        height: 16px;
    }

    .circle-icon_close {
        position: absolute;
        right: 275px;
        top: 18px;
        width: 16px;
        height: 16px;
    }

    .upload-icon {
        width: 16px;
        height: 19px;
        position: absolute;
        right: 18px;
        top: 14px;
    }

    .steps-wrapper {
        justify-content: start;
        align-items: center;
    }

    .id-verification-title {
        font-size: $regular;
        font-weight: bold;
    }

    .text-home-card-huge {
        @media (max-width: $tablet) {
            font-size: $largeplus;
        }
    }

    .h-separator {
        width: 23px;
        height: 2px;
        background-color: $purple-text;
    }

    .veriff-btn {
        height: 50px !important;
        padding: 0 50px;
    }

    .verification-form {
        max-width: 410px;
        width: 100%;
    }

    .verification-footer {
        margin-bottom: 0;

        @media (max-width: $widescreen) {
            margin-bottom: 190px;
        }

        @media (max-width: $phone) {
            margin-bottom: 0;
        }
    }

    .id-verification {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    @media (min-width: $mediumcreen) {
        .single-container {
            width: 922px;
        }
    }

    @media (max-width: $desktop) {
        .single-container {
            margin-bottom: 20px;
        }
    }

    @media (max-width: $tablet) {
        .single-container {
            padding: 17px 13px 17px 25px;
            flex-flow: column;
            justify-content: center;
            margin-bottom: 15px;
        }

        .input-outer-container {
            margin-left: 0;
        }

        .input-inner-container {
            margin-top: 4px;
            width: 100%;
        }

        .section-header {
            font-size: $regular;
        }

        .section-subtitle {
            font-size: $small;
        }

        .steps-wrapper {
            justify-content: start;
            align-items: flex-start;
        }

        .id-verification {
            flex-direction: column-reverse;
        }
    }

    .single-container {
        @media (max-width: $phone) {
            padding: 19px 15px;
        }
    }
    
    .checkmark {
        height: 20px;
        width: 20px;
        z-index: 1;
        left: 24px;
        top: 19px;
        content: url('/static/icons/fake_fill_checkmark.svg');
    }
    
    img.pending {
        height: 20px;
        width: 20px;
        z-index: 1;
        left: 19px;
        top: 19px;
        content: url('/static/icons/pending_unfilled.svg');
    }

    .phone {
        content: url('/static/icons/phone_unfilled.svg');
        margin-left: 8px;
        margin-right: 29px;
    }
    
    .email {
        content: url('/static/icons/email_unfilled.svg');
        margin-left: 4px;
        margin-right: 20px;
    }

    .finger {
        content: url('/static/icons/finger_unfilled.svg');
        margin-left: 4px;
        margin-right: 21px;
    }
    
    .address {
        content: url('/static/icons/address_unfilled.svg');
        margin-left: 4px;
        margin-right: 21px;
    }

    &.update:hover {
        .edit {
            display: block;
            margin-left: -16px;
        }
    }

    .section-icons, .edit {
        display: none;
    }
}
