@use '@material/select/_index' as select;
@import 'styles/variables';

#about-us {
    font-family: $primaryfont;

    .header-container {
        padding: 0 30px;

        @media (max-width: $desktop) {
            padding: 0;
        }
    }

    .header {
        padding-top: 110px;
        font-size: 38px;
        
        @media (max-width: $desktop) {
            padding-top: 0;
            font-size: $hometitletablet;
        }
    }

    .about-us-header {
        display: flex;
        font-size: $regular;
        letter-spacing: 0.98px;
        color: $black;
        width: 75%;
        padding-bottom: 3rem;
    }

    .cursor-default {
        cursor: default;
    }

    .solutions-cards {
        padding-bottom: 120px;

        @media (max-width: $desktop) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @media (max-width: $phone) {
            padding-top: 40px;
            padding-bottom: 65px;
        }
    }

    .card-grid {
        max-width: 1236px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 21px;
        padding: 0;
        
        @media (max-width: $widescreen) {
            grid-template-columns: auto;
            grid-row-gap: 16px;
        }

        @media (max-width: $phone) {
            gap: 15px;
        }
    }

    .cont-second-section {
        padding-top: 100px;
        padding-bottom: 137px;
        max-width: 1236px;
        padding-left: 0;
        padding-right: 0;

        @media (max-width: $desktop) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @media (max-width: $phone) {
            padding-top: 31px;
            padding-bottom: 40px;
        }
    }

    .cont-banner {
        height: 270px;
        max-width: 1236px;
        margin-bottom: 100px;

        @media (max-width: $desktop) {
            margin-bottom: 60px;
            height: 186px;
        }

        @media (max-width: $phone) {
            margin-bottom: 40px;
            height: 255px;
        }
    }

    .banner-second-section {

        & img {
            background-color: rgba(34, 34, 34, 0.911);
            border-radius: 4px;
            position: absolute;
            bottom: 0;
            height: 175px;
            width: 100%;

            @media (max-width: $desktop) {
                height: 134px;
            }

            @media (max-width: $phone) {
                height: 198px;
            }
        }
    }

    .height-second-banner {
        height: 292px;

        @media (max-width: $desktop) {
            height: 232px;
        }

        @media (max-width: $phone) {
            height: 255px;
        }
    }

    .img-for-banner {
        height: 100%;
        bottom: 0;
        right: 76px;
        width: 238px;

        @media (max-width: $desktop) {
            right: 29px;
            width: 164px;
            height: 186px;
        }

        @media (max-width: $phone) {
            bottom: 69px;
            left: 25%;
        }
    }

    .img-second-banner {
        left: 53px;
        top: 0;
        height: 273px;
        box-shadow: rgba(210, 214, 218, 0.082) 0 0 24px;

        @media (max-width: $desktop) {
            right: 29px;
            width: 116px;
            height: 209px;
        }

        @media (max-width: $phone) {
            width: 98px;
            height: 177px;
            left: 35%;
        }
    }

    .text-sect-banner {
        font-size: $mediumhugetitle;
        line-height: 63px;
        bottom: 56px;

        @media (max-width: $desktop) {
            font-size: $huge;
            height: 42px;
        }

        @media (max-width: $phone) {
            width: 100%;
            font-size: $largeplus;
            bottom: 32px;
        }
    }

    .text-left {
        left: 90px;

        @media (max-width: $desktop) {
            left: 34px;
        }

        @media (max-width: $phone) {
            left: 0;
        }
    }

    .text-right {
        right: 132px;

        @media (max-width: $desktop) {
            right: 41px;
        }

        @media (max-width: $phone) {
            right: 0;
        }
    }

    .title-about {

        & p {
            color: rgba(35, 39, 42, 1);
            margin-bottom: 22px !important;
            line-height: 69px;
            font-size: $extratextmore;

            @media (max-width: $desktop) {
                height: 30px;
                margin-bottom: 20px;
                line-height: 42px;
                font-size: $huge;
            }
    
            @media (max-width: $phone) {
                font-size: $largeplus;
            }
        }
    }

    .divisor-title {
        content: "";
        display: block;
        width: 92px;
        height: 5px;
        background: $ds-home-button;
    }

    .text-about {

        & p {
            margin-top: 32px !important;
            font-size: $large;
            line-height: 27px;
            color: $dsgray-text;

            @media (max-width: $desktop) {
                font-size: $medium;
                margin-top: 20px;
            }

            @media (max-width: $phone) {
                font-size: $regular;
            }
        }
    }

    .ds-promises {
        margin-top: 100px;
        margin-bottom: 100px;
        
        @media (max-width: $desktop) {
            display: flex;
            flex-direction: column-reverse;
            margin-top: 60px;
            margin-bottom: 60px;
        }

        @media (max-width: $phone) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    .cont-icons-prom {
        margin-top: 61px;

        @media (max-width: $desktop) {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    .icon-cont {
        margin-top: 14px;
    }

    .icon-prom {
        background: $lightpurple;
        width: 30px;
        height: 30px;
        border-radius: 4px;
        margin-right: 18px;
    }

    .text-icon {
        color: $text-btncontact;
        font-size: $largeplus;
        line-height: 30px;

        @media (max-width: $desktop) {
            font-size: $large;
        }

        @media (max-width: $phone) {
            font-size: $medium;
        }
    }

     .img-promise {
        max-width: 608px;
        margin-right: 50px;

        @media (max-width: $mediumcreen) {
            margin-right: 0;
        }

        @media (max-width: $desktop) {
           max-width: 95%;
           margin: auto;
        }

        @media (max-width: $phone) {
            max-width: 100%;
         }

        & img {
            @media (max-width: $desktop) {
                width: 100%;
                max-height: 447px;
            }
        }
    }

    .cont-text-promise {
        max-width: 588px;

        @media (min-width: $megascreen) {
            padding: 0;
        }

        @media (max-width: $desktop) {
            max-width: 100%;
        }
    }

    .top-title {

        & p {
            margin-top: 55px;

            @media (max-width: $desktop) {
                margin-top: 0;
            }
        }
    }

    .card-information-grid {
        margin-top: 82px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(285px, 1fr) );
        gap: 21px;

        @media (max-width: $desktop) {
            margin-top: 60px;
        }

        @media (max-width: $phone) {
            gap: 15px;
            margin-top: 40px;
        }
    }

    .reviews {
        max-width: 1480px;

        @media (max-width: $megascreen) {
            max-width: 1380px;
        }

        @media (max-width: $widescreen) {
            max-width: 1120px;
        }

        @media (max-width: $desktop) {
            max-width: 730px;
        }

        @media (max-width: $phone) {
            max-width: 355px;
        }
    }

    .container-reviews {
        max-width: 1236px;
        padding-top: 120px;
        padding-bottom: 120px;
        padding-left: 0;

        @media (max-width: $widescreen) {
            max-width: 800px;
        }

        @media (max-width: $desktop) {
            max-width: 660px;
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @media (max-width: $phone) {
            max-width: 284px;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    .glider-contain {
        margin-top: 77px;

        @media (max-width: $desktop) {
            margin-top: 40px;
        }
    }

    .glider {
        transition: transform 0.2s;
    }

    .star {
        color: rgba(254, 215, 102, 1);
    }

    .testimonial-card {
        background-color: $palegray;
        height: 209px !important;
        width: 387px !important;
        padding: 31px 31px 65px 24px;
        margin-right: 38px;
        border-radius: 4px;
        clip-path: polygon(0% 0%, 100% 0%, 100% 86%, 17% 87%, 13% 100%, 8% 87%, 0 87%);

        @media (max-width: $desktop) {
            width: 325px !important;
            margin-right: 10px;
        }

        @media (max-width: $phone) {
            width: 284px !important;
            height: 191px !important;
        }
    }
    
    .prev {
        left: 0;
    }

    .next {
        right: 0;
    }

    .navigation-arrow {
        border: 1.5px solid rgba(0, 0, 0, 0.9);
        border-radius: 50%;
        padding: 15.5px;
        top: 56%;

        @media (max-width: $desktop) {
            top: 234px;
            padding: 2px;
            font-size: $large;
        }

        @media (max-width: $phone) {
            top: 205px;
        }
    }
}
