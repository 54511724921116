@import 'styles/variables';

#security {
    .main {
        background: $white;
        border-radius: 4px;
        flex: 1 1 auto;
        max-height: 540px;
        height: 100%;
        max-width: 922px;
        padding: 31px 28px 56px 28px;

        @media (max-width: 1370px) {
            margin-left: -32px;
        }

        @media (max-width: $widescreen) {
            margin-left: 0;
            max-height: 868px;
            width: auto;
        }

        @media (max-width: $phone) {
            max-height: none;
        }
    }

    .container-security {
        display: grid;
        gap: 30px;
        grid-template-columns: minmax(294px, 445px) 1fr;

        @media (max-width: $widescreen) {
            display: flex;
            flex-direction: column;
        }
    }

    .email-container {
        background-image: url("/static/customer-portal/security-bg.png");
        border-radius: 4px;
        height: 345px;
        margin-top: 24px;

        @media (max-width: $desktop) {
            height: 328px;
            margin-top: 2rem;
        }
    }

    .title-form {
        margin-bottom: 24px;
    }

    .email-icon {
        max-width: 58px;
    }

    .info-text {
        padding: 9px;
        margin-top: 19px;
        border-radius: 4px;
        background-color: $mediumgray;
        line-height: 18px;
        letter-spacing: 0.48px;
    }

    .warning-text {
        padding: 9px;
        border-radius: 4px;
        background-color: $off-yellow;
        line-height: 18px;
        letter-spacing: 0.48px;
    }

    .qr-box {
        padding: 12px;
        border-radius: 4px;
        background-color: $divisor-gray;
    }

    .green-text {
        padding: 19px 10px;
        border-radius: 12px;
        background-color: $off-green;
    }

    .mini-spinner-icon {
        position: absolute;
        top: 12px;
        right: 15px;
        background-color: rgb(243, 245, 247);

        @media (max-width: $tablet) {
            top: 10px !important;
        }
    }

    button {
        height: 40px;
    }

    .two-factor-label {
        width: 48%;
        height: 50px;

        @media (max-width: $desktop) and (min-width: $tablet) {
            width: 100%;
        }

        @media (max-width: $phone) {
            width: 100%;
        }
    }

    .content-btn {
        margin-top: 9px;
    }

    .two-factor-button {
        float: right;
        width: 50%;

        @media (max-width: $desktop) and (min-width: $tablet) {
            width: 100%;
        }

        @media (max-width: $phone) {
            width: 100%;
        }
    }

    #ds-button {
        .primary-outlined {
            border: 2px solid rgba(87, 98, 213, 1) !important;
            border-radius: 4px !important;
        }

        .primary-outlined:not(:disabled) {
            color: rgba(87, 98, 213, 1) !important;
        }
    }

    .ds-buttom-dtf {
        width: 197px;
        height: 50px;
        padding: 0 15px !important;
    }

    .primary-outlined {
        border: 2px solid rgba(87, 98, 213, 1) !important;
    }

    .text-mt {
        margin-top: 20px;
    }

    .qr-code-img img {
        width: 90px;
        height: 94px;
    }

    .circle-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 16px;
        height: 16px;
        background-color: rgb(243, 245, 247);

        @media (max-width: $tablet) {
            top: 10px !important;
        }
    }

    .title {
        font-size: $largeplusplus;

        @media (max-width: $phone) {
            font-size: $large;
        }
    }

    .border-title::after {
        content: "";
        display: block;
        width: 54px;
        height: 5px;
        margin-top: 5px;
        margin-bottom: 15px;
        background: $ds-home-button;
    }

    @media (max-width: $tablet) {
        .warning-text {
            padding: 10px 10px 11px;
        }

        .green-text {
            padding: 10px 10px 11px;
        }

        .qr-box {
            padding: 5px;
        }

        .mini-spinner-icon {
            top: 40px;
        }

        .circle-icon {
            top: 45px;
        }
    }

    .validator {
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
    
    .progress {
        margin-left: 0 !important;
    }
}

