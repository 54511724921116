@use '@material/select/_index' as select;
@use 'styles/mixins' as m;
@import 'styles/variables';

#contact {
    font-family: $primaryfont;
    @include m.regular-container-paddings();
    background-color: $white;

    .cursor-default {
        cursor: default;
    }

    .ds-content {
        padding-bottom: 150px;

        @media (max-width: $desktop) {
            padding-bottom: 0;
        }
    }

    .ds-contact-title {
        font-size: $extratextmore;
        line-height: 58px;
        color: $ds-header;
        margin-top: 110px;
        margin-bottom: 55px;

        @media (max-width: $desktop) {
            font-size: $huge;
            line-height: 32px;
            margin-top: 50px;
            margin-bottom: 40px;
        }

        @media (max-width: $phone) {
            font-size: $largeplus;
            line-height: 8px;
        }
    }

    .border-title::after {
        content: "";
        display: block;
        margin-bottom: 34px;
        width: 92px;
        height: 5px;
        background: $ds-home-button;

        @media (max-width: $phone) {
            margin-bottom: 30px;
        }
    }

    .circle-icon {
        right: 12px;
        width: 16px;
        height: 16px;
    }

    .mini-spinner-icon {
        right: 10px;
    }

    .ds-text-form {
        max-width: 604px;

        @media (max-width: $extrascreen) {
            margin-right: 0;
        }

        @media (max-width: $desktop) {
            max-width: 100%;
        }
    }

    .cont-form-texts {
        @media (max-width: $desktop) {
            margin-left: 24px;
            margin-right: 24px;
        }
    }

    .ds-contact-text {
        font-size: $large;
        line-height: 27px;
        margin-top: 36px !important;
        color: $dsgray-text;

        @media (max-width: $desktop) {
            margin-top: 20px;
            font-size: $medium;
            line-height: 24px;
        }

        @media (max-width: $phone) {
            font-size: $regular;
            line-height: 21px;
        }
    }

    .contact-buttons {
        margin: 43px 0 50px 0;

        @media (max-width: $desktop) {
            margin: 50px 0 50px 0;
        }
    }

    .ds-btn-contact {
        border: none;
        width: 67px;
        height: 67px;
        margin-bottom: 4px;
        border-radius: 4px;

        @media (max-width: $tablet) {
            margin-right: 5px;
        }

        @media (max-width: $phone) {
            width: 55px;
            height: 55px;
        }
    }

    .ds-contact-form {
        background-color: $palegray;
        border-radius: 4px;
        padding: 50px 56px;

        @media (max-width: $desktop) {
            padding: 25px 25px;
        }

        @media (min-width: $desktop) {
            max-width: 608px;
        }
    }

    .ds-label-form {
        color: $gray-text;
        font-size: $regular;
        line-height: 16px;
    }

    .ds-form-contact {
        border: 0;
        transition: box-shadow 0.15s ease;
        border-radius: 4px;
        background-color: $white !important;

        &:focus {
            box-shadow: none !important;
        }
    }

    #input-email, #input-name {
        & > input {
            padding-right: 20px;
        }
    }

    .form-control {
        &:focus {
            box-shadow: none !important;
        }
    }

    .ds-select {
        @include select.outline-color($transparent);
        position: relative;
    }

    .ds-select-options {
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    }

    .mdc-select__menu {
        left: auto !important;
        right: 0 !important;
        position: absolute !important;
    }

    .mdc-list-item {
        &:hover {
            background-color: rgb(233, 236, 239, 0.05);
        }

        .mdc-list-item__primary-text {
            letter-spacing: 0.68px;
            color: rgba(33, 37, 41, 1);
            font-size: inherit;
            font-family: inherit;
        }
    }

    .btn-check:focus + .btn,
    .btn:focus {
        box-shadow: none;
    }

    .btn-send {
        background-color: rgba(84, 59, 239, 1) !important;
        margin-top: $largeplus;
        border-radius: 6px;
        margin-bottom: 5px;
        white-space: nowrap;
        width: 100%;
        height: 50px;

        & span:nth-child(2) {
            font-weight: 500;
            font-size: $medium;
            line-height: 19px;
        }
    }

    .ds-drop-zone-outside {
        position: relative;
        flex-direction: column;

        .mdc-text-field__resizer {
            resize: none;
            z-index: 5;
            height: 125px !important;
        }

        #drop-zone {
            display: none;
            height: 90%;
            width: calc(100% - 20px);
            box-sizing: border-box;
            border-radius: 10px;
            position: absolute;
            align-items: center;
            justify-content: center;
            z-index: -1;

            @media (max-width: $tablet) {
                margin-top: 0.25rem;
                border: none;
                align-content: end;
                height: 20px;
                display: contents;
            }
        }

        .wrapper-sm {
            height: 25px;
        }

        .drop-zone-lg {
            display: flex;

            & > div {
                color: $purple-text;
            }

            @media (max-width:$tablet) {
                display: none;
            }
        }

        @media (min-width: $tablet) {
            &:hover {
                .drop-zone-sm label {
                    display: flex;
                }
            }
        }

        @media (max-width: $tablet) {
            .drop-zone-sm label {
                display: flex;
            }
        }
    }

    .drop-zone-sm {
        min-width: 25px;

        & label {
            display: none;
        }
    }

    .hover-drop-zone {
        background-color: rgba(49, 15, 162, 0.05);

        #drop-zone {
            border: 1px dashed $purple-text;
            display: flex;
        }
    }

    .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 3px;
        margin-left: 3px;
    }

    .mdc-list-item__content .file-name {
        @extend .file-name;
    }

    #wrapper-sm {
        & .ds-first-files-list {
            width: 90%;

            & .mdc-list {
                list-style-type: none;
                display: flex;

                & .mdc-list-item {
                    padding-left: 0;
                    max-width: max-content;
                    flex: 1 1 0;

                    &.mdc-list-item--with-one-line {
                        float: left;
                        height: auto;

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.05);
                            border-radius: 4px;
                        }
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
            }
        }

        & .ds-dropdown-files-list {
            max-width: 15rem;
            padding: 0.25rem;
            transform: translate(0, 25px) !important;
            inset: 0 0 auto auto !important;

            & .mdc-list-item {
                padding-left: 0;

                &.mdc-list-item--with-one-line {
                    height: auto;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.05);
                        border-radius: 4px;
                    }
                }
            }

            & .mdc-list-item__content {
                pointer-events: auto;
                max-width: 170px;
            }
        }

        section[data-simplebar] {
            height: 205px !important;
            width: 205px;
            right: 190px;
            top: 0;

            & .ds-dropdown-files-list {
                overflow: auto;
                max-height: 200px;
                top: -25px !important;
            }
        }

        div.dropend {
            width: 25px;
        }
    }

    textarea::-webkit-scrollbar {
        width: 20px;
    }

    textarea::-webkit-scrollbar-thumb {
        background-clip: content-box;
        background-color: rgba(176, 176, 176, 0.5);
        border: 7px solid transparent;
        border-radius: 20px;

        &:hover {
            background-color: rgba(176, 176, 176, 0.6);
        }

        &:active {
            background-color: rgba(176, 176, 176, 0.7);
        }
    }

    .card {
        margin-bottom: 40px;
        border: none !important;
        border-radius: 4px !important;

        @media (max-width: $desktop) {
            margin-bottom: 20px;
        }
    }

    .card-container {
        padding: 29px 40px 13px 40px;

        @media (max-width: $tablet) {
            padding: 28px;
        }
    }

    .card-title {
        color: $dstext-gray;
        font-size: $homecardhuge;
        line-height: 39px;
        letter-spacing: 1.28px;

        p {
            margin-bottom: 22px;
        }

        @media (max-width: $desktop) {
            font-size: $largeplusplus;
            line-height: 33px;

            p {
                margin-bottom: 18px;
            }
        }

        @media (max-width: $phone) {
            font-size: $large;
            line-height: 27px;

            p {
                margin-bottom: 20px;
            }
        }
    }

    .ds-input--success {
        background: rgba(0, 180, 105, 0.05) !important;
    }
    
    .ds-input--error {
        background: rgba(245, 18, 18, 0.05) !important;
    }

    .card-text {
        font-size: $large;
        line-height: 27px;
        color: $dstext-gray;
        opacity: 0.75;

        @media (max-width: $desktop) {
            font-size: $medium;
            line-height: 24px;
        }

        @media (max-width: $phone) {
            font-size: $regular;
            line-height: 27px;
        }
    }

    .card-cont {
        margin-top: 60px;
        margin-bottom: 52px;
        min-height: 153px !important;

        @media (max-width: $desktop) {
            margin-top: 0;
            margin-bottom: 25px;
        }
    }

    .mtbtn {
        @media (max-width: $widescreen) {
            margin-bottom: 15px !important;
        }
    }

    .txt-btn {
        color: $gray-text !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill::first-line {
        transition: background-color 5000s ease-in-out 0s;
        z-index: inherit;
        -webkit-box-shadow: 0 0 0 30px transparent  inset !important;
        box-shadow: 0 0 0 30px  transparent inset !important;
    }
}
