@import 'styles/variables';

#order-detail {
    #orders {
        padding-top: 30px;
        padding-left: 30px;
        padding-bottom: 0;
        border-radius: 12px;
    
        .single-container {
            padding-top: 0;
        }

        @media (max-width: $tablet) {
            padding-top: 20px;
            padding-left: 20px;
        }
    }

    @media (max-width: $desktop) {
        .get-help {
            width: 63px;
            margin-right: 5px !important;
        }
    }

    .divisor-tablegray {
        margin-top: 8px;
        margin-bottom: 12px;
        color: $tablegray;
        opacity: 1 !important;
    }

    .cost-title {
        font-size: $extralarge;

        @media (max-width: $phone) {
            font-size: $large;
        }
    }

    .about {
        font-family: $roboto;
        font-size: $largeplus;

        @media (max-width: $tablet) {
            font-size: $large;
        }
    }

    .btn {
        height: 50px !important;
        padding: 0 27px;
    }

    .id-margin {
        margin-bottom: 0.68rem !important;
    }

    .button-container {
        width: auto;

        @media (max-width: ($tablet - 1)) {
            width: 100%;

            .btn {
                width: 192px !important;
            }
        }

        @media (max-width: $phone) {
            padding: 0 2px;

            .btn {
                width: 294px !important;
            }
        }
    }

    .payment-method-icon {
        max-width: 24px;
        max-height: 100%;
    }

    .recipient {
        height: 34px;
        width: auto;
        border-radius: 12px;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 15% 85%;
        padding: 0 10px;
        align-items: center;
        column-gap: 7px;

        img {
            max-height: 20px;
        }
    }

    .status-column {
        width: 130px !important;
    }

    .title {
        font-size: $homecardhuge;

        @media (max-width:$desktop) {
            font-size: $largeplusplus;
        }

        @media (max-width:$phone) {
            font-size: $large;
        }
    }

    .return {
        transform: translateX(-10px);
        margin-bottom: 15px;
    }
    .table-title {
        color: $black;
        font-size: $medium;
        font-weight: 700;
    }

    .img-container {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $palegray;
        margin-right: 14px;
        .product-img {
            width: 16px;
            height: 18px;
            object-fit: contain;
            filter: brightness(0) saturate(100%) invert(47%) sepia(35%) saturate(6945%) hue-rotate(220deg) brightness(92%) contrast(106%);
        }
    }

    .table {
        width: 90%;

        @media (max-width: $phone) {
            width: 100%;
        }
    }

    .payment-method {
        color: $menu-purple;
    }
    
    .bottom-elements-font {
        font-size: $medium;

        @media (max-width: $desktop) {
            font-size: $regular;
        }
    }

    .table-border {
        border-bottom: 1px solid $tablegray;

        @media(max-width: $tablet) {
            border: 0;
        }
    }

    .mobile-grid {
        display: none;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 95px;
        grid-row-gap: 9px;

        @media (max-width: $phone) {
            display: grid;
        }
    }

    #ds-status-tag .tag {
        height: auto !important;
    }
}
