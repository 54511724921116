@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#customer-app {
    @include m.regular-container-paddings();
    background-color: v.$lightergray;

    .mdc-menu-surface {
        z-index: 5;
    }

    .main {
        background: v.$white;
        border-radius: 4px;
        flex: 1 1 auto;
        padding: v.$smallPad 0;
    }

    .customer-container {
        max-width: 1238px;
    }

    section {
        margin: 0 !important;
    }

    .portal-content {
        display: flex;

        .portal-inner-content {
            max-width: 922px !important;
        }
    }

    .profile-margin {
        font-size: v.$extrahuge;
        margin-left: 0;
    }

    .spin-loader {
        margin-top: 8rem;
    }

    .sidebar {
        flex: 0 0 296px;
        min-width: 296px;
        z-index: 10;
        margin-right: v.$regularPad;
    }

    @include m.media(v.$widescreen) {
        .sidebar {
            flex: 0 0 v.$regularPad;
            margin-right: 0;
        }

        .container-fluid {
            padding-inline: v.$regularPad;
        }

        .portal-content {
            flex-direction: column;
            margin-top: 6px !important;
        }

        .profile-margin {
            margin-left: 30px;
            font-size: v.$morehuge;
        }

        .main {
            margin-left: 0;
        }
    }

    @include m.media(v.$phone) {
        .portal-content {
            flex-direction: column;
            margin-top: 0 !important;
        }

        .container-fluid {
            padding-inline: v.$smallPad;
        }

        .profile-margin {
            margin-left: 11px;
        }

        .spin-loader {
            margin-top: 5rem;

            .mdc-circular-progress {
                @include m.square(100px);
            }
        }
    }
}
