@import "styles/variables";
@import "styles/mixins";

#auth {
    width: 100%;

    .row {
        margin-left: unset;
        margin-right: unset;
    }

    .logo-container {
        @media (max-width: 1280px) {
            position: absolute;
            height: 20px;
        }
    }

    .logo-col {
        background-image: url("/static/images/divica_sales_bg1.svg");
        background-repeat: no-repeat;
        background-size: 100vh;
        width: 250px;

        .desktop-logo-padding {
            margin-top: 13px;
            margin-left: 32px;
            max-width: 142px;
            max-height: 14px;
        }

        @media (max-width: 1280px) {
            max-width: unset;
        }

        @media (min-width: 1281px) {
            flex: 0 0 auto;
            display: inline;
        }

        @media (max-width: 1280px) {
            display: none !important;
        }
    }

    .logo-icon img {
        @media (max-width: 1280px) {
            content: url("/static/logo/ds_logo_black.svg");
        }
    }

    .txt-col {
        padding: calc(50vh - 342px) 78.5px 34px;

        h1 {
            color: $purple-text;
        }

        @media (max-width: 1280px) {
            padding: 267px 158px 34px;
        }

        @media (max-width: $phone) {
            padding: 185px 53px 34px;

            h1 {
                font-size: $morehuge;
            }
        }
    }

    .img-col {
        flex: 1 0 0%;
        display: flex !important;
        @include flex-center();
        background-color: $ds-blue;
        padding: 0;

        @media (max-width: 1280px) {
            display: none !important;
        }
    }

    .form-img {
        width: 100vw;
        height: 100%;
        object-fit: cover;
    }

    .return {
        color: $sign-up-blue;
        cursor: pointer;
        top: calc(50vh - 434px);
        left: 78.5px;


        @media (max-width: 1280px) {
            left: unset !important;
            top: 205px !important;
        }

        @media (max-width: $phone) {
            top: 123px !important;
        }

        .back-button {
            color: $sign-up-blue;
            font-size: 22px;
        }
    }

    @media (max-width: 1280px) {
        background: $white;

        .auth-container {
            background-color: $white;
        }

        .auth-footer {
            opacity: 75%;
            font-size: $small;
        }
    }

    @media (max-width: ($phone)) {
        & .row.vh-100 {
            width: 100%;
            margin-right: 0px;
            margin-left: 0px;
        }
    }

    .logo-position {
        position: relative;
        z-index: 3;

        @media (max-width: 1280px) {
            margin: 20px 0 0 158px;
            left: 0;
            top: 0;
        }

        @media (max-width: $phone) {
            left: 0;
            right: 0;
            margin: 20px 0 0 53px;
            width: auto;
            height: auto;
        }

        @media (min-width: 1281px) {
            display: none !important;
        }
    }

    .chicksds-logo {
        width: 186px;
        height: 18x;
    }

    .form-position {
        display: flex;
        align-items: center;
        max-width: 608px;
        min-height: 100vh;
        margin-top: auto;
        margin-bottom: auto;

        @media (max-width: 1280px) {
            max-width: 100%;
            display: unset;
            align-items: unset;
        }

        @media (min-width: 1280px) {
            display: unset;
        }
    }

    ds-auth-form {
        flex: 0 0 100%;
    }

    $checkbox-size: 18px;

    .checkbox {
        &:hover .mdc-checkbox__native-control ~ .mdc-checkbox__background {
            border: 1px solid rgba(84, 59, 239, 0.5) !important;
        }

        .mdc-checkbox__native-control {
            @include full-size();
            ~ .mdc-checkbox__background {
                @include square($checkbox-size);
                border-radius: 3px;
                background-color: rgb(243, 245, 247);
                border-color: rgb(243, 245, 247);
            }

            &:enabled:checked ~ .mdc-checkbox__background {
                background-color: rgb(84, 59, 239) !important;
            }
        }
    }

    .mdc-checkbox {
        @include square($checkbox-size);
        flex: 0 0 $checkbox-size;
    }

    .mdc-checkbox__checkmark {
        $h: 15px;
        $w: 18px;
        height: $h;
        width: $w;
        top: calc(50% - $h / 2);
        left: calc(50% - $w / 2);
        color: white;
    }
}