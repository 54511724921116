@import "styles/variables";
@import "styles/mixins";

#currency-app {
    padding-bottom: 120px;

    .mdc-text-field__affix--suffix {
        padding-right: 12px;
    }

    .mdc-select__selected-text {
        color: $ds-home !important;
    }

    .text-total {
        font-size: $regular;

        @media (max-width: $desktop) {
            font-size: $small;
        }

        @media (max-width: $phone) {
            font-size: $regular;
        }
    }

    .unit-price {
        font-size: $extralarge;

        @media (max-width: $desktop) {
            font-size: $large;
        }

        @media (max-width: $phone) {
            font-size: $extrahuge;
        }
    }

    .currency-area {
        margin-bottom: 80px;
        max-width: 1260px;

        @media (max-width: $desktop) {
            margin-bottom: 60px;
        }

        @media (max-width: $phone) {
            margin-bottom: 40px;
            max-width: 335px;
        }
    }

    .game-content-container {
        max-width: 1260px;

        @media (max-width: $phone) {
            max-width: 335px;
        }
    }

    .border-padding-mobile {
        @media (max-width: $phone) {
            padding: 0 !important;
        }
    }

    .main-title {
        font-size: $extrahuge;

        @media (max-width: $desktop) {
            font-size: $hometitletablet;
        }

        @media (max-width: $phone) {
            font-size: $extralarge;
        }
    }

    .main-content-block {
        background: $white;
        border-radius: 5px;
    }

    .input-password:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
        color: $ds-home;
    }

    .price-symbol {
        @media (max-width: $desktop) {
            font-size: 14px !important;
        }

        @media (max-width: $phone) {
            font-size: 18px !important;
        }
    }

    .stock-font {
        font-family: "Roboto", sans-serif;
        color: $ds-home-button;
        font-size: $regular;
        line-height: 16px;

        @media (max-width: $tablet) {
            font-size: $small;
        }
    }

    .main-block {
        padding-right: 0 !important;
        @media (min-width: $tablet) and (max-width: $desktop) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: $desktop) {
            padding: 0 !important;
        }
    }

    .quantity-field {
        @media (min-width: $tablet) and (max-width: $desktop) {
            width: 144px;
        }
    }

    .character-field {
        @media (min-width: $tablet) and (max-width: $desktop) {
            padding-left: 6px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .title {
                align-self: start;
                margin-left: 10px;
            }
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    .first-container {
        min-width: 922px;
        min-height: 362px;
        margin-right: 20px !important;
        &.firt-container-height {
            height: 362px;
        }

        @media (max-width: $extrascreen) {
            min-width: 0 !important;
        }

        @media (min-width: $phone) and (max-width: $desktop) {
            padding: 20px !important;
        }

        @media (max-width: $phone) {
            width: 335px;
        }

        @media (max-width: $desktop) {
            margin-right: 0 !important;
            &.firt-container-height {
                height: auto;
            }
        }
    }

    .second-container {
        height: 131px;
        align-items: center;
        background-color: $white;

        @media (min-width: $phone) and (max-width: $desktop) {
            height: 148px !important;
        }

        @media (max-width: $extrascreen) {
            min-width: 0;
            height: auto;
        }
    }

    .third-container {
        background-color: $white;
        height: 248px;
        align-content: center;
        display: flex;
        flex-direction: column;

        ds-line.w-100 {
            #ds-line {
                width: 100%;

                div {
                    width: 100% !important;
                }
            }
        }

        @media (min-width: $extrascreen) {
            min-width: 0;
        }

        @media (min-width: $tablet) and (max-width: $desktop) {
            padding: 15px 25px 15px 27px;

            &.static-height {
                height: 256px;
            }
        }

        @media (max-width: $desktop) {
            height: auto;
            align-content: unset;
            display: block;
            flex-direction: unset;
        }

        @media (max-width: $tablet) {
            padding: 15px 25px 8px 27px;
        }
    }

    #ds-join-banner {
        margin-top: 80px;
    }

    #ds-template-content-separator {
        height: 0;
        border-width: 0;
        border-bottom-width: 1px;
    }

    .filter-values-width {
        @media (min-width: $phone) and (max-width: $desktop) {
            width: 66px;
        }
    }

    .unit-price-default {
        font-size: 50px;
    }

    .cart-title {
        font-family: $primaryfont;
        letter-spacing: 0.96px;
        font-size: $extralarge;

        @media (max-width: $tablet) {
            font-size: $large;
        }

        @media (max-width: $phone) {
            font-size: $extralarge;
        }
    }

    .select-background-filled {
        border-radius: 4px;
    }

    .mdc-text-field--disabled.mdc-text-field--outlined {
        background-color: $slateblue-off-low-opacity;
        opacity: 0.2;
    }

    .input-password.mdc-text-field--disabled .mdc-text-field__input::placeholder {
        color: $ds-home;
    }

    .paragraph-alignment #page-content-area p {
        margin-bottom: 0 !important;
    }

    #checkout-text {
        position: absolute;
        white-space: nowrap;
    }

    .button-mobile {
        width: 175px;
        height: 50px;

        @media (max-width: $desktop) {
            height: 46px;
        }

        @media (max-width: $phone) {
            width: 247px;
            height: 50px;
        }
    }

    .buy-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px !important;
        height: 50px;
        border: none;
        border-radius: 4px;
        list-style: 24px;
        background-color: $ds-home-button;
        color: $white;
        font-size: $medium;
        cursor: pointer;

        @media (max-width: $desktop) {
            width: 100% !important;
        }

        @media (max-width: $phone) {
            font-size: $small;
            height: 41px;
        }
    }

    .button-blue-filled.mdc-ripple-upgraded--background-focused .mdc-button__ripple:before {
        opacity: 0;
    }

    > .container {
        padding: 120px 0 0 0;
    }

    @media (max-width: $desktop) {
        .unit-price-default {
            font-size: 40px;
        }
    }

    @media (max-width: $tablet) {
        .container {
            max-width: 543px;
        }

        div:has(> .first-container) {
            $pad: 17px;
            max-width: 356px + $pad;
            width: 100%;
            padding: 0 $pad 0 0;
        }

        .first-container {
            @include flex-center(column);
        }

        .main-block {
            max-width: 170px;
            width: 100%;
        }

        .second-container {
            @include padding-y(47.5px, false, true);
        }

        .third-container {
            height: 243px;
        }

        #ds-item-category-banner {
            grid-column-gap: 35.5px;
            padding: 30px;
        }

        > .container {
            padding-top: 50px;
        }
    }

    @media (max-width: $phone) {
        .main-block {
            max-width: 100%;
        }

        .second-container {
            @include padding-y(27px, true, true);
        }

        .first-container {
            @include container-fluid();

            > .row {
                @include container-fluid();

                > div {
                    @include padding-x(0);
                }
            }
        }

        #ds-game-selector .mdc-select {
            @include container-fluid();
        }
    }

    .quantity-field:hover {
        #ds-number-arrows {
            opacity: 1;
            visibility: visible;
        }
    }
}
