@import 'styles/variables';

#cart {
    .container-cart {
        margin-top: 206px;
        margin-bottom: 123px;

        @media (min-width: $mediumcreen) {
            max-width: 1250px;
        }

        @media (max-width: $tablet) {
            max-width: 728px;
            margin-top: 111px;
            margin-bottom: 50px;
        }
        
        @media (max-width: $phone) {
            max-width: 335px;
            margin-top: 96px;
            margin-bottom: 35px;
        }
    }

    .lock-icon {
        max-width: 13px;
    }

    .cont-title-cart {
        padding: 16px 40px;

        @media (max-width: $desktop) {
            border-bottom: 1px solid rgba(59, 60, 81, 0.2);
        }

        @media (max-width: $tablet) {
            padding: 20px 20px;
        }
    }

    .title-cart  {
        @media (max-width: $tablet) {
            font-size: $large !important;
        }
    }

    .order-container {
        margin-top: 17px;
    }

    .cont-card {
        margin: 0 auto auto auto;
        padding-bottom: 40px !important;

        @media (max-width: $widescreen) {
            max-width: 880px !important;
        }

        @media (max-width: $phone) {
            padding-bottom: 0 !important;
        }
    }

    .summary-area {
        top: 120px;
    }

    .cart-card {
        max-width: 922px !important;
        padding: 0;

        @media (max-width: $widescreen) {
            margin-bottom: 30px;
        }

        @media (max-width: $tablet) {
            margin-bottom: 69px;
        }

        @media (max-width: $phone) {
            margin-bottom: 20px;
            min-height: 136px !important;
        }

        @media (max-width: $desktop) {
            min-height: 123px !important;
        }
    }

    .title-order {
        margin-top: 40px;

        @media (max-width: $desktop) {
            margin-top: 25px;
            font-size: $large;
        }

        @media (max-width: $phone) {
            margin-top: 20px;
            font-size: $medium;
        }
    }

    .cont-products-title {
        letter-spacing: 0.384px;
        color: rgba(26, 18, 60, 1);
    }

    .iframe-area {
        height: 100%;
        width: 100%;
    }

    .previous-card {
        min-width: 267px;
        min-height: 111px;
        background-color: $palegray;
        transition: background-color 0.5s ease, border-color 0.5s ease;
        border: 1px solid $transparent;

        .static-width {
            width: 173px;
            margin-left: 10px;

            @media (max-width: $desktop) {
                width: 133px;
            }

            @media (max-width: $tablet) {
                width: 58.33%;
            }
        }

        &.selected {
            border: 1px solid $ds-home-button;
        }

        &.expired {
            border: 1px solid $red;
        }

        &.expiring-soon {
            border: 1px solid $yellow;
        }

        .card-image {
            max-width: 45px;
            width: 42px;
        }

        .card-billing-address {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .close-icon-card {
        height: 10px;
        opacity: 0;
        transition: visibility 0.3s linear, opacity 0.3s linear;
        visibility: hidden;
        filter: brightness(0%);
        width: 10px;

        @media (min-width: $tablet) and (max-width: $desktop) {
            &.close-icon-card-expired {
                margin-right: 6px;
            }
        }
    }

    .letter-spacing {
        letter-spacing: 0.13px;
    }

    .previous-card:hover {
        border: 1px solid rgba(87, 98, 213, 0.4);

        .close-icon-card {
            opacity: 1;
            visibility: visible;
        }
    }

    #card-logo {
        position: absolute;
        right: 16px;
    }

    #help-icon img {
        height: 20px;
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .lock-icon {
        max-height: 16px;
        max-width: 13px;
    }

    .exp-date-label {
        width: 132px !important;
        letter-spacing: 0.45px;

        @media (max-width: $desktop) {
            width: auto !important;
        }
    }

    .circle-icon-expiry {
        height: 16px;
        position: absolute;
        right: 25px;
        top: 61px;
        width: 16px;

        @media (max-width: $phone) {
            top: 58px;
        }
    }

    .cart-products-area {
        @media (max-width: $desktop) {
            padding-top: 16px;
            padding-bottom: 16px;
        }

        @media (max-width: $tablet) {
            padding: 16px 0 0 0;
        }

        @media (max-width: $phone) {
            padding: 16px 0;
        }

        .cart-products-area-title {
            padding: 12px 0 12px 42px;
            border-top: 1px solid rgba(112, 112, 112, 0.15);
            border-bottom: 1px solid rgba(112, 112, 112, 0.15);
        }
    }
    
    .margin-cart-login {
        margin-top: 40px;

        @media (max-width: $desktop) {
            margin-top: 30px;
        }

        @media (max-width: $phone) {
            margin-top: 20px;
        }
    }

    .back-color {
        color: rgba(35, 39, 42, 1);
    }

    .return {
        color: $ds-home-button;
        cursor: pointer;
        margin-left: -20px;

        .back-button {
            color: $ds-home-button;
        }
    }

    .opacity-text {
        opacity: 0.75;
    }

    .select-background-outlined:not(.mdc-select--disabled).mdc-select--invalid .mdc-select__anchor,
    .select-background-outlined:not(.mdc-select--disabled).ds-input--error .mdc-select__anchor,
    .select-background-outlined:not(.mdc-select--disabled).ds-input--success .mdc-select__anchor {
        background-color: unset;
    }

    .ds-input--success .mdc-text-field__input, .ds-input--success .mdc-select .mdc-text-field--input, .ds-input--success .mdc-select__selected-text {
        color: $ds-home !important;
    }

    @media (max-width: $desktop) {
        .return {
            margin-left: 0;
        }
    }

    @media (max-width: $phone) {
        .container {
            padding: 0 20px;
        }
    }

    .order-card {
        min-height: 276px;
        max-width: 1236px;
        margin-top: 40px !important;
        margin-bottom: 80px !important;
        border-radius: 4px !important;
        border: none;
        margin: auto;

        @media (max-width: $desktop) {
            margin-top: 30px !important;
            margin-bottom: 60px !important;
        }

        @media (max-width: $phone) {
            margin-top: 20px !important;
            max-width: 345px;
            margin-bottom: 40px !important;
        }
    }

    .col-form {
        padding: 56px 0 0 88px;

        @media (max-width: $desktop) {
            padding: 75px 0 0 21px;
        }

        @media (max-width: $desktop) {
            padding: 39px 31px 0 31px;
        }
    }

    .card-title {
        max-width: 225px;
        line-height: 35px;
        letter-spacing: 1.28px;

        @media (max-width: $desktop) {
            font-size: $largeplusplus;
            line-height: 27px;
        }

        @media (max-width: $desktop) {
            font-size: $large;
        }
    }

    .box {
        background: $white;
        border-radius: 4px;
        padding: 33px 40px 37px;

        @media (max-width: $tablet) {
            padding: 20px;
        }

        @media (max-width: $phone) {
            padding: 17px;
        }
    }

    .box-shadow-card {
        box-shadow: 0 1px 8px 0 rgba(151, 151, 151, 0.08);
    }

    .purchase-flow-spacing {
        @media (min-width: $desktop) {
            margin-top: 40px;
        }
    }

    .grid-justify-self-end {
        justify-self: end;
    }

    .summary-content-grid-row {
        letter-spacing: 0px !important;
        display: grid;
        grid-auto-flow: column;
        height: 50px;
        padding: 7px 0px;
        border-bottom: solid 1px $divisor-gray-light;
    }

    .gray-border-bottom {
        padding-bottom: 38px;
    }

    .checkout-area {
        background-color: $white;
    }

    @media (max-width: $phone) {

        .payment-top {
            grid-auto-flow: row;
            height: auto;

            .grid-justify-self-end {
                justify-self: unset;
            }
        }

        .payment-component {
            display: block;
            width: 100%;
        }
    }

    .img-card-order {
        right: 0;
        bottom: 0; 
    }

    .buy-button {
        margin-top: 30px;
        width: 135px !important;
        height: 50px;
        border: none;
        border-radius: 4px;
        background-color: $ds-home-button;
        font-size: $medium;
        cursor: pointer;
    }

    .order-card {
        line-height: 39px;
        letter-spacing: 1.28px;  
    }

    #ds-number-arrows  {
        top: 17.4px;
    }

    .ds-form-control {
        &:focus {
            box-shadow: none;
        }
    }

    .ds-input--success {
        background: rgba(0, 180, 105, 0.05);
    }

    .ds-input--error {
        background: rgba(253, 74, 74, 0.05);
    }

    .area-payment-method {
        padding: 20px 20px 40px;

        @media (max-width: $tablet) {
            margin-top: 6px;
        }

        @media (max-width: $phone) {
            margin-top: 16px;
        }

        @media (max-width: $tablet) {
            padding: 20px;
        }
    }

    .back-payment-method {
        margin-bottom: 22px;
    }

    .text-banner {
        @media (max-width: $phone) {
            margin-top: 95px !important;
        }
    }
}
